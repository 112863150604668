<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th sort-key="sales_office">Sales Office</vs-th>
        <vs-th sort-key="sales_office_desc">Sales Office Desc</vs-th>
        <vs-th sort-key="cust_code">Cust Code</vs-th>
        <vs-th sort-key="billing_number">Billing Number</vs-th>
        <vs-th sort-key="cust_payment_number">Cust Payment Number</vs-th>
        <vs-th sort-key="doc_date">Doc Date</vs-th>
        <vs-th sort-key="billing_date">Posting Date</vs-th>
        <vs-th sort-key="due_date">Due Date</vs-th>
        <vs-th sort-key="billing_amount">Billing Amount</vs-th>
        <vs-th sort-key="cust_payment_amount">Cust Payment Amount</vs-th>
        <vs-th sort-key="remain_amount">Remain Amount</vs-th>
        <vs-th sort-key="document_type">Document Type</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="update_by">Updated By</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td> </vs-td> -->
          <vs-td :data="data[indextr].sales_office">
            {{ data[indextr].sales_office }}
          </vs-td>
          <vs-td :data="data[indextr].sales_office_desc">
            {{ data[indextr].sales_office_desc }}
          </vs-td>
          <vs-td :data="data[indextr].cust_code">
            {{ data[indextr].cust_code }}
          </vs-td>
          <vs-td :data="data[indextr].billing_number">
            {{ data[indextr].billing_number }}
          </vs-td>
          <vs-td :data="data[indextr].cust_payment_number">
            {{ data[indextr].cust_payment_number }}
          </vs-td>
          <vs-td :data="data[indextr].doc_date">
            {{ dateFormat(data[indextr].doc_date) }}
          </vs-td>
          <vs-td :data="data[indextr].billing_date">
            {{ dateFormat(data[indextr].billing_date) }}
          </vs-td>
          <vs-td :data="data[indextr].due_date">
            {{ dateFormat(data[indextr].due_date) }}
          </vs-td>
          <vs-td :data="data[indextr].billing_amount">
            {{ formatPrice(data[indextr].billing_amount) }}
          </vs-td>
          <vs-td :data="data[indextr].cust_payment_amount">
            {{ formatPrice(data[indextr].cust_payment_amount) }}
          </vs-td>
          <vs-td :data="data[indextr].remain_amount">
            {{ formatPrice(data[indextr].remain_amount) }}
          </vs-td>
          <vs-td :data="data[indextr].document_type">
            {{ data[indextr].document_type }}
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
          <vs-td :data="data[indextr].update_by">
            {{ data[indextr].update_by }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    startDocDate: {
      type: Date,
    },
    endDocDate: {
      type: Date,
    },
    startDueDate: {
      type: Date,
    },
    endDueDate: {
      type: Date,
    },
    openKeyDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/table", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              status: this.status,
              territory_ids: this.territoryIDs,
              start_posting_date: this.startPostingDate
                ? moment(this.startPostingDate).format("YYYY-MM-DD")
                : null,
              end_posting_date: this.endPostingDate
                ? moment(this.endPostingDate).format("YYYY-MM-DD")
                : null,
              start_doc_date: this.startDocDate
                ? moment(this.startDocDate).format("YYYY-MM-DD")
                : null,
              end_doc_date: this.endDocDate
                ? moment(this.endDocDate).format("YYYY-MM-DD")
                : null,
              start_due_date: this.startDueDate
                ? moment(this.startDueDate).format("YYYY-MM-DD")
                : null,
              end_due_date: this.endDueDate
                ? moment(this.endDueDate).format("YYYY-MM-DD")
                : null,
              open_key_date: this.openKeyDate
                ? moment(this.openKeyDate).format("YYYY-MM-DD")
                : null,
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        "ARTRANS_REPORT_" +
        (this.territoryNames.length > 0
          ? this.territoryNames.join("-")
          : "all") +
        "_";

      if (this.startDocDate || this.endDocDate) {
        fileTitle +=
          "DOC_DATE_" +
          moment(this.startDocDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDocDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startPostingDate || this.endPostingDate) {
        "POSTING_DATE_" +
          moment(this.startPostingDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endPostingDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startDueDate || this.endDueDate) {
        "DUE_DATE_" +
          moment(this.startDueDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDueDate).format("YYYY-MM-DD") +
          "_";
      }

      fileTitle +=
        "OPEN_KEY_DATE_" + moment(this.openKeyDate).format("YYYY-MM-DD");

      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            territory_ids: this.territoryIDs,
            start_posting_date: this.startPostingDate
              ? moment(this.startPostingDate).format("YYYY-MM-DD")
              : null,
            end_posting_date: this.endPostingDate
              ? moment(this.endPostingDate).format("YYYY-MM-DD")
              : null,
            start_doc_date: this.startDocDate
              ? moment(this.startDocDate).format("YYYY-MM-DD")
              : null,
            end_doc_date: this.endDocDate
              ? moment(this.endDocDate).format("YYYY-MM-DD")
              : null,
            start_due_date: this.startDueDate
              ? moment(this.startDueDate).format("YYYY-MM-DD")
              : null,
            end_due_date: this.endDueDate
              ? moment(this.endDueDate).format("YYYY-MM-DD")
              : null,
            open_key_date: this.openKeyDate
              ? moment(this.openKeyDate).format("YYYY-MM-DD")
              : null,
            file: file,
            title: fileTitle,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>